<template>
  <div class="cart">
    <div class="cart-step cart-step-1">
      <div class="cart-step-1-top">
        <h3 class="step-title"><span>{{ $t('cart.step_1') }}</span>{{ $t('cart.step_1_desc') }}</h3>
        <div :class="(show_sale) ? 'sale-info sale-info-shown' : 'sale-info'">
          <div class="sale-info-text">{{ $t('cart.disc_2') }}</div>
          <div class="sale-info-percent">-10%</div>
        </div>
        <div class="cart-pack-image"></div>
      </div>
      <div class="cart-step-1-bottom">
        <h2 class="main-title">Garcinia Cambogia</h2>
        <div class="counter-area">
          <div class="counter-text">{{ $t('cart.quantity') }}</div>
          <vue-numeric-input :class="'stepper-counter'" v-model="order.amount" :min="1" :max="100" :step="1"></vue-numeric-input>
        </div>
        <div class="price-area">
          <div class="price-text">{{ $t('cart.price') }}</div>
          <div class="price-value">
            <div class="old-price-value">{{ (order.amount > 1) ? (price * order.amount) + ' ' + $t('grn') : '' }}</div>
            <div :class="(order.amount > 1) ? 'new-price-value new-price-value-sale' : 'new-price-value'">{{ (order.amount > 1) ? Math.round(price * order.amount * 0.9) + ' ' + $t('grn') : (price * order.amount) + ' ' + $t('grn') }}</div>
          </div>
        </div>
      </div>
      <div class="next-step-button" @click="goToStep(2)">{{ $t('button.next') }} <img src="/assets/images/next-arrow.png" /></div>
    </div>
    <div class="cart-step cart-step-2">
      <div class="back-step-link" @click="goToStep(1)">{{ $t('button.back') }}</div>
      <div class="cart-step-2-title">
        <h3 class="step-title"><span>{{ $t('cart.step_2') }}</span>{{ $t('cart.step_2_desc') }}</h3>
      </div>
      <div class="cart-step-2-content">
        <form>
          <div class="row">
            <div class="col-md-12">
              <h5 class="form-group-title">{{ $t('cart.contact_data') }}</h5>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="orderInputName" class="form-label">{{ $t('cart.name') }}</label>
                <input type="text" v-model="order.name" class="form-control" id="orderInputName" :placeholder="$t('cart.firstname_lastname')">
                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="orderInputPhone" class="form-label">{{ $t('cart.phone') }}</label>
                <input type="text" v-model="order.phone" class="form-control" id="orderInputPhone" placeholder="+38 (0XX) XXX XX XX">
                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h5 class="form-group-title">{{ $t('cart.payment_data') }}</h5>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="orderPaymentMethod" class="form-label">{{ $t('cart.payment_method') }}</label>
                <v-select :options="payment_methods" v-model="order.payment_method" id="orderPaymentMethod"></v-select>
                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h5 class="form-group-title">{{ $t('cart.delivery_data') }}</h5>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="orderCity" class="form-label">{{ $t('cart.city') }}</label>
                <v-select :options="cities" v-model="order.city" v-on:input="citySelected($event)"></v-select>
                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="orderWarehouse" class="form-label">{{ $t('cart.warehouse') }}</label>
                <v-select :options="warehouses" v-model="order.np_warehouse"></v-select>
                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
              </div>
            </div>
          </div>
          <!-- <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Check me out</label>
          </div> -->
        </form>
      </div>
      <div class="next-step-button" @click="goToStep(3)">{{ $t('button.next') }} <img src="/assets/images/next-arrow.png" /></div>
    </div>
    <div class="cart-step cart-step-3">
      <div class="back-step-link" @click="goToStep(2)">{{ $t('button.back') }}</div>
      <div class="cart-step-3-title">
        <h3 class="step-title"><span>{{ $t('cart.step_3') }}</span>{{ $t('cart.step_3_desc') }}</h3>
      </div>
      <div class="cart-step-3-instruction">
        {{ $t('cart.order_notion') }}
      </div>
      <div class="mt-auto">
        <div class="cart-step-3-summary">
          <h5 class="form-group-title">{{ $t('cart.summary') }}</h5>
          <div class="summary-table">
            <div class="d-flex summary-row">
              <div>Garcinia Cambogia (2уп по 60таб)</div>
              <div class="ms-auto">299 {{ $t('grn') }} / {{ $t('pcs') }}</div>
            </div>
            <div class="d-flex summary-row">
              <div>{{ $t('cart.delivery_data') }}</div>
              <div class="ms-auto">{{ $t('cart.carrier_rate') }}</div>
            </div>
            <div class="d-flex summary-row">
              <div>{{ $t('cart.discount') }}</div>
              <div class="ms-auto">{{ (order.amount > 1) ? '-10%' : '-' }}</div>
            </div>
            <div class="d-flex summary-final">
              <div>{{ $t('cart.total') }}</div>
              <div class="ms-auto">{{ (order.amount > 1) ? Math.round(price * order.amount * 0.9) + ' ' + $t('grn') : (price * order.amount) + ' ' + $t('grn') }}</div>
            </div>
          </div>
        </div>
        <div class="cart-step-3-button" @click="submitOrder">
          {{ $t('button.buy') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumericInput from 'vue-numeric-input';
import router from "../router";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {
    VueNumericInput
  },
  data: function () {
    return {
      order: {
        amount: 1,
        name: "",
        phone: "",
        payment_method: "",
        city: "",
        np_warehouse: "",
      },
      show_sale: false,
      orderSuccesed: false,
      price: 299,
      payment_methods: [{label: this.$t('cart.card_transfer'), code: 'card'}, {label: this.$t('cart.cash_on_delivery'), code: 'nal'}],
      cities: [],
      warehouses: []
    }
  },
  mounted() {
    fetch('https://garcinia-cambogia.club/api/np.php?cityList=true')
      .then(response => response.json())
      .then(json => {
        this.cities = json
        // setTimeout(() => {
        //   this.cities = json
        //   this.loading = false
        // }, 1000)
       })
    setTimeout(() => {
      this.show_sale = true
    }, 1000)
  },
  methods: {
    // toggleMenu() {
    //   this.showMenu = !this.showMenu
    //   this.setBodyClass()
    // },
    submitOrder() {
      console.log("submit");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: this.order })
      };
      console.log(requestOptions)
      fetch("https://garcinia-cambogia.club/api/", requestOptions)
        .then(response => response.text())
        // .then(data => (this.postId = data.id));
        .then(this.orderSuccesed = true)
        // .then(setTimeout(() => {  this.modal.hide() }, 3000))
        // .then(this.modalSuccesed = false)
        .then(router.push({ name: "Success"}));
      // if (this.title.trim()) {
      //   const newTodo = {
      //     id: Date.now(),
      //     title: this.title,
      //     completed: false
      //   }
      //   this.$emit('add-todo', newTodo)
      //   this.title = ''
      // }
    },
    citySelected(event) {
      console.log(event.code);
      fetch('https://garcinia-cambogia.club/api/np.php?warehouseList=true&city=' + event.code)
        .then(response => response.json())
        .then(json => {
          console.log(json)
          this.warehouses = json
         })
    },
    goToStep(id) {
      var body = document.body
      if(id == 1) {
        body.classList.add('cart-current-step-1')
        body.classList.remove('cart-current-step-2')
        body.classList.remove('cart-current-step-3')
      }
      if(id == 2) {
        body.classList.remove('cart-current-step-1')
        body.classList.add('cart-current-step-2')
        body.classList.remove('cart-current-step-3')
      }
      if(id == 3) {
        body.classList.remove('cart-current-step-1')
        body.classList.remove('cart-current-step-2')
        body.classList.add('cart-current-step-3')
      }

    }
  }
}
</script>

<style lang="scss">
$golden-color: #C9AD81;

.cart {
  margin-top: 90px;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: space-between;
}
.cart-step {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: calc(100% / 3);
  }
}
.cart-step-2, .cart-step-3 {
  display: none;
}
.cart-step-1-top {
  width: 100%;
  background: radial-gradient(103.27% 103.27% at 50% 109.64%, rgba(201, 173, 129, 0.9) -10%, rgba(0, 0, 0, 0.9) 100%);
  position: relative;
  padding-bottom: 180px;
  .sale-info {
    color: #fff;
    font-size: 1em;
    padding: 0em 2em;
    line-height: 1;
    font-weight: 600;
    text-align: right;
    transform: translateY(20px);
    opacity: 0;
    &.sale-info-shown {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.5s ease-in-out 5s, opacity 0.5s ease-in-out 5s;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.5em;
    }
    .sale-info-percent {
      color: #F35A5A;
      font-size: 2em;
    }
  }
  .cart-pack-image {
    position: absolute;
    background-image: url(/assets/images/cart-pack.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    max-height: 200px;
    background-size: contain;
  }
}
.cart-step-1-bottom {
  width: 100%;
  background: #000;
  h2 {
    padding: 1em 0.5em;
    text-align: center;
    letter-spacing: 0;
    margin-bottom: 0;
    @media screen and (min-width: 768px) and (max-width: 1459.98px) {
      &.main-title {

        font-size: 2em;
      }
    }
  }
}
.cart-step-2-content, .cart-step-3-instruction, .cart-step-3-summary  {
  padding: 12px;
  @media screen and (min-width: 768px) {
    padding: 1.5rem;
  }
  @media screen and (min-width: 1460px) {
    padding: 50px;
  }
}
.cart-step-3-instruction {
  font-size: 1.5em;
  color: rgba(0, 0, 0, 0.3);
}
.back-step-link {
  color: $golden-color;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 600;
  margin: 1em;
  margin-bottom: 0;
  &:before {
    content: "";
    width: 20px;
    height: 16px;
    margin-right: 0.2em;
    background: url(/assets/images/back-arrow.png);
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  .cart-step-2-content {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .back-step-link {
    display: none;
  }
}

.cart-step-1 {
  display: flex;
  flex-flow: wrap;
  background: #000;
}

body.cart-current-step-2 {
  .cart-step-1, .cart-step-3 { display: none; }
  .cart-step-2 {
    display: flex;
    flex-direction: column;
  }
}
body.cart-current-step-3 {
  .cart-step-1, .cart-step-2 { display: none; }
  .cart-step-3 {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .cart-step-1, .cart-step-2, .cart-step-3 {
    display: flex;
    flex-direction: column;
  }
}
.next-step-button, .cart-step-3-button {
  background: #C9AD81;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 2em;
  text-align: center;
  font-weight: 600;
  padding: 0.5em;
  width: 100%;
  height: fit-content;
  @media screen and (min-width: 768px) {
    font-size: 2em;
    padding: 0.5em;
  }
  @media screen and (min-width: 1460px) {
    font-size: 3em;
    padding: 30px;
  }
}
.cart-step-3-button:hover {
  background: #F35A5A;
  cursor: pointer;
}
.next-step-button {
  margin-top: auto;
}
@media screen and (min-width: 768px) {
  .next-step-button {
    display: none;
  }
}
.step-title {
  font-size: 1em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-bottom: 0;
  @media screen and (min-width: 768px) {
    font-size: 1.25em;
    text-align: center;
  }
  @media screen and (min-width: 1460px) {
    font-size: 1.5em;
    text-align: center;
  }
  .cart-step-1-top & {
    color: #fff;
  }
  span {
    letter-spacing: 0.05em;
    color: $golden-color;
    font-size: 2em;
    font-weight: bold;
    margin-right: 0.2em;
  }
}
.counter-area, .price-area {
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  .counter-text, .price-text {
    font-size: 1.5em;
    margin-right: 1em;
  }
}
.counter-area {
  margin-bottom: 2em;
  @media screen and (min-width: 1460px) {
    margin-bottom: 3em;
  }
}
.price-area {
  align-items: flex-end;
}
.old-price-value {
  text-align: right;
  font-size: 2em;
  font-weight: bold;
  text-decoration: line-through;
  line-height: 1;
  min-height: 2rem;
  // min-height:
}
.new-price-value {
  font-weight: bold;
  font-size: 3em;
  line-height: 1;
}
.new-price-value-sale {
  color: #F35A5A;
}
.stepper-counter {
  font-size: 3em;
  width: 4em !important;
  color: #fff;
  input.numeric-input {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center !important;
    background: none;
    color: $golden-color;
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
  button.btn {
    width: 3rem;
    height: 3rem;
    color: #fff;
    padding: 0;
    top: 7px;
    border: none;
    background: none;
    box-shadow: none;
    &:focus { outline: none; }
    &:active { box-shadow: none; }
  }
  &.vue-numeric-input .btn-decrement .btn-icon:before {
    background-color: #fff;
  }
  &.vue-numeric-input .btn-increment .btn-icon:after, &.vue-numeric-input .btn-increment .btn-icon:before {
    background-color: #fff;
  }
}

.form-group-title {
  font-weight: 500;
  font-size: 1.2em;
  @media screen and (min-width: 768px) {
    font-weight: 600;
    font-size: 1.5em;
  }
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0.5em;
}
.form-control, .vs__dropdown-toggle {
  background: rgba(0, 0, 0, 0.02);
  border: 0px;
  padding: 0.5rem 0.75rem;
  .vs__clear {
    position: relative;
    top: -1px;
  }
}
.form-label {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0.2em;
  @media screen and (min-width: 768px) {
    margin-bottom: 0.5em;
  }
}
.summary-row {
  margin-bottom: 0.5em;
}
.summary-table {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
  line-height: 1.5;
}
.summary-final {
  font-size: 1.5em;
  color: #F35A5A;
  font-weight: 600;
}
</style>
